<template>
  <Default>
    <template v-slot:breadcrumb>
      <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item>Anasayfa</a-breadcrumb-item>
        <a-breadcrumb-item>Fiyatlar</a-breadcrumb-item>
      </a-breadcrumb>
    </template>
    <template v-slot:content>
      <div class="content">
        <div class="content-head">
          <h3>Paketler ve Fiyatlar</h3>
        </div>

        <a-tabs v-model:activeKey="activeKey">

          <a-tab-pane key="1" tab="Katalog Fiyatları">
            <a-table :rowKey="record => record.id" :dataSource="catalogList" :columns="catalogListColumns" bordered>
              <template #actions="{ record }">
                <a-button type="link" @click="catalogEditBtn(record)">Fiyat & Ürün Sınırı Düzenle</a-button>
              </template>
            </a-table>

            <a-modal v-model:visible="catalogListEditModalVisible" title="Fiyat ve Ürün Sınırı Düzenle">
              <a-form :model="catalogListEditForm" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-item label="Katalog Fiyatı">
                  <a-input type="number" v-model:value="catalogListEditForm.price"></a-input>
                </a-form-item>
                <a-form-item label="Ürün Sınırı">
                  <a-input type="number" v-model:value="catalogListEditForm.product_limit"></a-input>
                </a-form-item>
              </a-form>
              <template #footer>
                <a-button @click="catalogListEditModalVisible = false">İptal</a-button>
                <a-button type="primary" @click="catalogListEditSaveBtn">Kaydet</a-button>
              </template>
            </a-modal>
          </a-tab-pane>

          <a-tab-pane key="2" tab="Servis Fiyatları" force-render>
            <a-table :rowKey="record => record.id" :dataSource="serviceList" :columns="serviceListColumn" bordered>
              <template #serviceNumber="{ record }">
                {{ typeof record.children === "undefined" ? '-' : record.children.length }}
              </template>
              <template #price="{ text }">
                <span>{{ text ? text : '-' }}</span>
              </template>
              <template #actions="{ record }">
                <a-button v-if="record.price" @click="servicePriceEditBtn(record)" type="link">Fiyat Düzenle</a-button>
              </template>
            </a-table>

            <a-modal v-model:visible="serviceListEditModalVisible" title="Servis Fiyatını Düzenle">
              <a-form :model="serviceListEditForm" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-item label="Servis Fiyatı">
                  <a-input type="number" v-model:value="serviceListEditForm.price"></a-input>
                </a-form-item>
              </a-form>
              <template #footer>
                <a-button @click="serviceListEditModalVisible = false">İptal</a-button>
                <a-button type="primary" @click="servicePriceSaveBtn">Kaydet</a-button>
              </template>
            </a-modal>
          </a-tab-pane>

          <!--          <a-tab-pane key="3" tab="Yenilenme Süresi Fiyatları">-->
          <!--            <a-table :rowKey="record => record.id" :dataSource="refreshSettingList" :columns="refreshSettingListColumn"-->
          <!--                     bordered>-->
          <!--              <template #actions="{ record }">-->
          <!--                <a-button type="link">Fiyat Düzenle</a-button>-->
          <!--              </template>-->
          <!--            </a-table>-->
          <!--          </a-tab-pane>-->

        </a-tabs>

      </div>
    </template>
    <template v-slot:mTitle>Fiyatlar | Bill</template>
  </Default>
</template>

<script>
import Default from "@/components/_layouts/Default";
import {defineComponent, ref} from 'vue';
import instance from "@/http/axios";
import {message} from "ant-design-vue";

export default defineComponent({
  name: "Home",
  components: {
    Default
  },
  data() {
    return {
      activeKey: ref('1'),

      /* catalogList and column */
      catalogList: [],
      catalogListColumns: [
        {
          title: 'Katalog Adı',
          dataIndex: 'name',
          width: '20%'
        },
        {
          title: 'Fiyat',
          dataIndex: 'price',
          width: '15%'
        },
        {
          title: 'Ürün Sınırı',
          dataIndex: 'product_limit',
          width: '15%'
        },
        {
          title: 'İşlemler',
          width: '30%',
          slots: {
            customRender: 'actions'
          }
        },
      ],
      catalogListEditModalVisible: false,
      catalogListEditForm: {},
      /* catalogList and column end */

      /* serviceList and Column */
      serviceList: [],
      serviceListColumn: [
        {
          title: 'Servis Adı',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Alt Servis Sayısı',
          slots: {
            customRender: 'serviceNumber'
          }
        },
        {
          title: 'Fiyat',
          dataIndex: 'price',
          slots: {
            customRender: 'price'
          }
        },
        {
          title: 'İşlemler',
          slots: {
            customRender: 'actions'
          }
        },
      ],
      serviceListEditModalVisible: false,
      serviceListEditForm: {},
      /* serviceList and Column end */

      /* refreshSettingslist and column */
      refreshSettingList: [],
      refreshSettingListColumn: [
        {
          title: 'Adı',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: 'Fiyatı',
          dataIndex: 'price',
          key: 'price'
        },
        {
          title: 'İşlemler',
          slots: {
            customRender: 'actions'
          }
        }
      ],
      /* refreshSettingslist and column end */

      labelCol: {span: 6},
      wrapperCol: {span: 14},
    }
  },
  methods: {
    catalogListEditSaveBtn() {
      let formData = new FormData();
      formData.append('id', this.catalogListEditForm.id)
      formData.append('price', this.catalogListEditForm.price)
      formData.append('product_limit', this.catalogListEditForm.product_limit)

      instance.post('/catalog/update/catalog', formData).then(response => {
        if (response.data.code === 200) message.success(response.data.msg)
        else message.error(response.data.msg)
      })
    },
    catalogEditBtn(item) {
      this.catalogListEditModalVisible = true
      this.catalogListEditForm = item
    },
    servicePriceSaveBtn() {
      let formData = new FormData();
      formData.append('id', this.serviceListEditForm.id)
      formData.append('price', this.serviceListEditForm.price)
      formData.append('parent', this.serviceListEditForm.parent)

      instance.post('/catalog/update/service', formData).then(response => {
        if (response.data.code === 200) message.success(response.data.msg)
        else message.error(response.data.msg)
      })
    },

    servicePriceEditBtn(item) {
      this.serviceListEditModalVisible = true
      this.serviceListEditForm = item
    },
    fetchCatalog() {
      instance.get('/catalog/get').then(response => {
        this.catalogList = response.data.catalogs.catalogs
        this.serviceList = response.data.catalogs_services.catalogs_services
        this.serviceList.forEach((v) => {
          v.children = v.services
          delete v.services
        })
        this.refreshSettingList = response.data.catalogs_services_refresh_settings.catalogs_services_refresh_settings
      })
    }
  },
  created() {
    this.fetchCatalog()
  }
})
</script>

<style scoped>
.content {
  padding: 24px;
  background: #fff;
  min-height: 360px;
}

.content-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
</style>